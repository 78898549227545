import { GameTeaserProps, useGameTeaser } from '@/components/GameTeaser';
import { HomeGameTeaserEventPriority } from '@/components/HomeGameTeaser';
import HomeGameTeaserBadge from '@/components/HomeGameTeaserBadge';
import LinkWrapper from '@/components/LinkWrapper';
import Logo from '@/components/Logo';
import { teaserTimeTable } from '@/config/home/teaserTimeTable';
import { getGameById } from '@/helpers/getGameById';
import { getNextJackpot } from '@/helpers/getNextJackpot';
import { isJackpotExpired } from '@/helpers/isJackpotExpired';
import formatMillionCurrency from '@/utilities/formatMillionCurrency';
import replaceAbbreviations from '@/utilities/replaceAbbreviations';
import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';

export enum HomeGameTeaserItemVariant {
  OneThird = 'HomeGameTeaserItem--oneThird',
  Half = 'HomeGameTeaserItem--half',
  TwoThirds = 'HomeGameTeaserItem--twoThirds',
  Full = 'HomeGameTeaserItem--full',
  Hidden = 'HomeGameTeaserItem--hidden',
  OneFourth = 'HomeGameTeaserItem--oneFourth',
}

type HomeGameTeaserItemProps = GameTeaserProps<{
  variant: HomeGameTeaserItemVariant;
  isHighlight?: boolean;
  priority?: HomeGameTeaserEventPriority;
  order?: number;
  href?: string;
  children?: React.ReactNode;
}>;

const HomeGameTeaserItem = (props: HomeGameTeaserItemProps) => {
  const { config, state } = useGameTeaser(props);
  const [chanceLabel, setChanceLabel] = useState<string>();
  const [badgeLabel, setBadgeLabel] = useState<{
    short: string;
    long: string;
  }>();

  const getBadgeId = useCallback((): number | null => {
    const gameId = config ? config.id : -1;
    const addition = teaserTimeTable.additions.find((item) => {
      return item.game === gameId;
    });
    return addition ? addition.additionalGame : null;
  }, [config]);

  useEffect(() => {
    async function fetchData() {
      const badgeId = getBadgeId();
      const badgeConfig = getGameById(badgeId ? badgeId : -1);
      const addition =
        badgeConfig &&
        teaserTimeTable.additions.find((item) => {
          return item.additionalGame === badgeConfig.id;
        });
      const jackpot = addition && (await getNextJackpot(badgeConfig));
      const dataValue = jackpot ? jackpot.value : 0;
      if (
        addition &&
        jackpot &&
        !isJackpotExpired(jackpot) &&
        Number(dataValue) >= addition.threshold
      ) {
        setBadgeLabel({
          short: formatMillionCurrency(parseInt(jackpot.value as string), true),
          long: jackpot.label,
        });
        if (config) {
          const label = config.chanceDesc;
          setChanceLabel(
            `${config.title} ${label.split(', Kl')[0]}, ${badgeConfig.title} ${
              badgeConfig.chanceDesc
            }`
          );
        }
      }
    }

    fetchData();
  }, [getBadgeId]);

  const badgeId = badgeLabel && getBadgeId();
  const { order, isHighlight, priority, href } = props;
  const banderole = config?.jackpotDescAlt || '';
  const themeClass = config ? `HomeGameTeaserItem--${config.shortname}` : '';
  const jackpotLength = state.jackpot.includes('Millionen') ? 'long' : 'short';

  return (
    <div
      style={{ order }}
      className={classnames('HomeGameTeaserItem', props.variant, themeClass, {
        'HomeGameTeaserItem--banderole': banderole,
        'HomeGameTeaserItem--highlight': isHighlight,
        [`HomeGameTeaserItem--${priority}-priority`]:
          typeof priority !== 'undefined',
        ['HomeGameTeaserItem--webp']: config?.shortname === 'keno',
      })}
    >
      {config
        ? [
            <LinkWrapper
              href={href || config.urls.game}
              className="HomeGameTeaserItem__box"
              key={config.logo}
              style={{ position: 'relative' }}
              tabIndex={0}
              >
              <div className="HomeGameTeaserItem__container">
                <Logo
                  className="HomeGameTeaserItem__logo"
                  variant={config.logo}
                />
                <span
                  className="HomeGameTeaserItem__subtitle"
                  dangerouslySetInnerHTML={replaceAbbreviations(state.subtitle)}
                ></span>
                <strong
                  className={classnames(
                    'HomeGameTeaserItem__jackpot',
                    `HomeGameTeaserItem__jackpot--${jackpotLength}`
                  )}
                >
                  {state.jackpot}
                </strong>
                {(() => {
                  if (
                    (config.shortname === 'eurojackpot' &&
                      state.jackpotClass1 >= 90 &&
                      state.jackpotClass2 >= 15) ||
                    (config.shortname === 'lotto6aus49' &&
                      state.jackpotClass2 >= 3)
                  ) {
                    return (
                      <span
                        className="HomeGameTeaserItem__second_winning"
                        dangerouslySetInnerHTML={replaceAbbreviations(
                          `${state.jackpotClass2} Mio. € in Gewinnklasse 2`
                        )}
                      ></span>
                    );
                  } else {
                    return '';
                  }
                })()}
                <small
                  className={classnames('HomeGameTeaserItem__chance', {
                    'HomeGameTeaserItem__chance--small': badgeId,
                  })}
                  dangerouslySetInnerHTML={replaceAbbreviations(
                    chanceLabel || config.chanceDesc
                  )}
                ></small>
                {badgeId && (
                  <HomeGameTeaserBadge
                    gameConfig={getGameById(badgeId)}
                    badgeLabel={badgeLabel}
                    highlight={isHighlight}
                  />
                )}
                <span
                  className="banderole"
                  dangerouslySetInnerHTML={replaceAbbreviations(banderole)}
                ></span>
              </div>
            </LinkWrapper>,
          ]
        : props.children}
    </div>
  );
};

export default HomeGameTeaserItem;
