import Logo from '@/components/Logo';
import { GameConfig } from '@/config/game';
import replaceAbbreviations from '@/utilities/replaceAbbreviations';
import classnames from 'classnames';

export default function HomeGameTeaserBadge({
  gameConfig,
  badgeLabel,
  highlight,
}: {
  gameConfig: GameConfig;
  badgeLabel: {
    short: string;
    long: string;
  };
  highlight?: boolean;
}) {
  return (
    badgeLabel && (
      <div
        className={classnames(
          'HomeGameTeaserBadge',
          `HomeGameTeaserBadge--${gameConfig.shortname}`,
          {
            'HomeGameTeaserBadge--highlight': highlight,
          }
        )}
      >
        <Logo
          className={classnames('HomeGameTeaserBadge__logo')}
          variant={gameConfig.logo}
        />
        {Object.keys(badgeLabel).map((variant) => (
          <span
            key={variant}
            className={classnames(
              'HomeGameTeaserBadge__label',
              `HomeGameTeaserBadge__label--${variant}`
            )}
            dangerouslySetInnerHTML={replaceAbbreviations(badgeLabel[variant])}
          />
        ))}
      </div>
    )
  );
}
